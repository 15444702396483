import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useCustomToast } from "../../siswa/toast";
import axios from "../../../helper/axios";

const {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Select,
} = require("@chakra-ui/react");

const mesin = [
    { serial: '616202024382585', cloud_id: 'C2636CF4DB221831' },
    { serial: '616202024382574', cloud_id: 'C2636CF4DB072B31' },
    { serial: '616202024382590', cloud_id: 'C2636CF4DB192534' },
    { serial: '616202024382592', cloud_id: 'C2636CF4DB121A31' },
]

export const ModalAdd = ({ data, reload, setReload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const customToast = useCustomToast();
  const token = localStorage.getItem("token");
  const { NPS, Nama } = data

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      const data = {
        pin: NPS,
        name: Nama,
        cloud_id: value?.mesin
      }
      await axios.post("/finger/user", data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Siswa Berhasil Didaftarkan",
        status: "success",
      });
      setReload(!reload)
      onClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  return (
    <>
      <Button
        size={{ lg: "sm", base: "xs" }}
        colorScheme="blue"
        onClick={onOpen}
      >
        Daftarkan
      </Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Daftar Fingerprint</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="10px">
              anda akan menambahkan {Nama} ke mesin Fingerprint
            </Text>
            <Formik
              initialValues={{
                mesin: "",
              }}
              validationSchema={Yup.object().shape({
                mesin: Yup.string().required("mesin is required"),
              })}
              onSubmit={(data, action) => {
                handleSubmit(data);
                action.resetForm();
              }}
            >
              {({ values, dirty, setFieldValue }) => {
                return (
                  <Flex
                    as={Form}
                    justifyContent="center"
                    alignItems="start"
                    gap="2"
                    m="20px 0"
                  >
                    <Box w="100%">
                      <Select
                        placeholder=" ~~ pilih mesin ~~"
                        defaultValue=""
                        name="mesin"
                        onChange={(e) => setFieldValue("mesin", e.target.value)}
                      >
                        {mesin
                          .map((item, index) => {
                            return (
                              <option key={index} value={item.cloud_id}>
                                {item.serial}
                              </option>
                            );
                          })}
                      </Select>
                      <ErrorMessage
                        component="div"
                        name="mesin"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </Box>
                    <Button
                      colorScheme="blue"
                      type="submit"
                      isLoading={loading}
                    >
                      Add
                    </Button>
                  </Flex>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
