import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import { FiUser, FiUsers, FiHome, FiSettings } from "react-icons/fi";
import { MdOutlineHomeWork } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { IoStatsChartOutline, IoFingerPrint } from "react-icons/io5";
import { Link } from "react-router-dom";

const Menu = [
  {
    menu: "Siswa",
    icon: FiUser,
    subMenu: [
      { desc: "Daftar Siswa", link: "/daftar-siswa" },
      { desc: "Daftar Siswa Baru", link: "/daftar-siswa-baru" },
      { desc: "Daftar Kandidat KUI", link: "/daftar-kandidat-kui" },
      { desc: "Mutasi", link: "/" },
    ],
  },
  {
    menu: "Guru",
    icon: FiUsers,
    subMenu: [{ desc: "Daftar Guru", link: "/daftar-guru" }],
  },
  // {
  //   menu: "Rekap",
  //   icon: FiFileText,
  //   subMenu: [
  //     { desc: "Rekap Siswa", link: "/" },
  //     { desc: "Rekap Siswa", link: "/" },
  //   ],
  // },
  {
    menu: "Kelas",
    icon: FiHome,
    subMenu: [
      { desc: "Daftar Kelas", link: "/daftar-kelas" },
      { desc: "Daftar Kelas Program", link: "/daftar-program" },
    ],
  },
  {
    menu: "Kamar",
    icon: MdOutlineHomeWork,
    subMenu: [{ desc: "Daftar Kamar", link: "/daftar-kamar" }],
  },
  {
    menu: "Fingerprint",
    icon: IoFingerPrint,
    subMenu: [{ desc: "Siswa", link: "/finger-siswa" }],
  },
  {
    menu: "Report",
    icon: IoStatsChartOutline,
    subMenu: [{ desc: "Statistik", link: "/report" }],
  },
  {
    menu: "Setting",
    icon: FiSettings,
    subMenu: [{ desc: "Tambah Admin", link: "../tambah-admin" }],
  },
];

export const MenuButton = () => {
  return (
    <Accordion allowToggle>
      <Flex _hover={{ bgColor: "gray.100" }} cursor="pointer" as={Link} to="/">
        <Flex alignItems="center" h="30px" gap="10px" m="8px 16px">
          <Flex w="10" justifyContent="center">
            <Icon w="5" h="5" as={AiOutlineHome} />
          </Flex>
          <Text fontSize="14px">Dashboard</Text>
        </Flex>
      </Flex>
      {Menu.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            w={{ lg: "20vw", base: "250px" }}
            border="none"
          >
            <AccordionButton>
              <Flex alignItems="center" w="90%" h="30px" gap="10px">
                <Flex w="10" justifyContent="center">
                  <Icon w="5" h="5" as={item.icon} />
                </Flex>
                <Text fontSize="14px">{item.menu}</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            {item.subMenu.map((item2, index) => {
              return (
                <AccordionPanel key={index} pb={2} cursor="pointer">
                  <Flex
                    alignItems="center"
                    w="100%"
                    h="30px"
                    gap="10px"
                    as={Link}
                    to={item2.link}
                  >
                    <Flex w="10" />
                    <Text fontSize="14px">{item2.desc}</Text>
                  </Flex>
                </AccordionPanel>
              );
            })}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
