import {
    Box,
    Flex,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";
  import { useEffect, useState } from "react";
  import { useLocation } from "react-router-dom";
  import axios from "../../../helper/axios";
  import { useSelector } from "react-redux";
import { truncateString } from "../../../helper/word";
import Loading from "../../loading";
import { Pagination } from "../../siswa/pagination";
import { ModalAdd } from "./add";
import { EditFinger } from "./edit";

const mesin = [
    { serial: '616202024382585', cloud_id: 'C2636CF4DB221831' },
    { serial: '616202024382574', cloud_id: 'C2636CF4DB072B31' },
    { serial: '616202024382590', cloud_id: 'C2636CF4DB192534' },
    { serial: '616202024382592', cloud_id: 'C2636CF4DB121A31' },
]
  
  const TableFingerSiswa = () => {
    const token = localStorage.getItem("token");
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const searchParams = new URLSearchParams(location.search);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const nowPage = searchParams.get("page") || 1;
    const tingkat = searchParams.get("tingkat") || "";
    const search = searchParams.get("search") || "";
    const status = searchParams.get("status") || "registered";
    const user = useSelector((state) => state.userSlice.value);
  
    const getData = async () => {
      try {
        setLoading(true)
        const { data } = await axios.get(
          `/finger?ket=${status}&page=${nowPage}&tingkat=${tingkat}&nama=${search}&nps=${search}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        setData(data.data);
        setPage(data.totalPages);
        setLoading(false)
      } catch (err) {
        console.log(err);
        setLoading(false)
      }
    };
  
    useEffect(() => {
      getData();
      window.scroll(0, 0);
    }, [nowPage, search, tingkat, status, reload]);
  
    if (loading) {
      return <Loading />;
    }
    return (
      <Box mb="30px">
        <TableContainer>
          <Table variant="simple">
            <TableCaption>daftar siswa tahun ajaran {user.tahun_pelajaran}</TableCaption>
            <Thead fontSize="14px">
              <Tr>
                <Th>NO</Th>
                <Th display={{ md: "none", base: "table-cell" }}>Siswa</Th>
                <Th display={{ md: "table-cell", base: "none" }}>NPS</Th>
                <Th display={{ md: "table-cell", base: "none" }}>NAMA LENGKAP</Th>
                <Th display={{ md: "table-cell", base: "none" }}>TINGKAT</Th>
                <Th display={status === "unregistered" ? "none" : { md: "table-cell", base: "none" }}>MESIN</Th>
              </Tr>
            </Thead>
            <Tbody fontSize="14px" bgColor="white">
              {data.map((item, index) => {
                console.log(item);
                
                return (
                  <Tr key={index}>
                    <Td maxW="5px">{100 * (nowPage - 1) + index + 1}</Td>
                    <Td display={{ md: "none", base: "table-cell" }}>
                      <Flex direction="column" fontSize="14px">
                        <Text fontSize="14px" fontWeight="bold">
                          {truncateString(item.siswa_biodata.Nama, 12)}
                        </Text>
                        <Text fontSize="12px">{item.siswa_biodata.NPS}</Text>
                        <Text fontSize="12px">{item?.Tingkat}</Text>
                        <Text fontSize="12px">{mesin.find(i => i.cloud_id === item.siswa_finger?.cloud_id)?.serial}</Text>
                      </Flex>
                    </Td>
                    <Td display={{ md: "table-cell", base: "none" }}>
                      {item.siswa_biodata.NPS}
                    </Td>
                    <Td display={{ md: "table-cell", base: "none" }}>
                      {truncateString(item.siswa_biodata.Nama, 20)}
                    </Td>
                    <Td display={{ md: "table-cell", base: "none" }}>
                      {item?.Tingkat}
                    </Td>
                    <Td display={status === "unregistered" ? "none" : { md: "table-cell", base: "none" }}>
                      {mesin.find(i => i.cloud_id === item.siswa_finger?.cloud_id)?.serial}
                    </Td>
                    <Td display={status === "unregistered" ? "none" : { md: "table-cell" }}>
                      <EditFinger pin={item.siswa_biodata.NPS} isSync={item.siswa_finger?.isSync} />
                    </Td>
                    <Td display={status === "registered" ? "none" : "table-cell"}>
                        <ModalAdd data={item.siswa_biodata} reload={reload} setReload={setReload} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {data.length === 0 ? null : (
          <Pagination totalPages={+page} currentPage={+nowPage || 1} />
        )}
      </Box>
    );
  };
  
  export default TableFingerSiswa;
  