import { Flex, Icon } from "@chakra-ui/react"
import { IoFingerPrint, IoSync, IoSyncCircleSharp } from "react-icons/io5"
import axios from "../../../helper/axios"
import { useCustomToast } from "../../siswa/toast";

export const EditFinger = ({ pin, isSync }) => {
    const token = localStorage.getItem("token");
    const customToast = useCustomToast()

    const onAddFinger = async () => {
        try {
            await axios.patch("/finger/user", { pin, verification: "1" }, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })

            customToast({
                title: "Success",
                description: "Permintaan terkirim ke mesin!",
                status: "success",
            });
        } catch (err) {
            console.log(err);
            customToast({
                title: "Warning",
                description: `${err.response.data || 'terjadi kesalahan'}`,
                status: "warning",
            });
        }
    }
    const onSyncFinger = async () => {
        try {
            await axios.patch("/finger/user/sync", { pin }, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })

            customToast({
                title: "Success",
                description: "Permintaan terkirim ke mesin!",
                status: "success",
            });
        } catch (err) {
            console.log(err);
            customToast({
                title: "Warning",
                description: `${err.response.data || 'terjadi kesalahan'}`,
                status: "warning",
            });
        }
    }
    return (
        <Flex gap={2}>
            <Icon w="5" h="5" as={IoFingerPrint} cursor='pointer' onClick={onAddFinger} />
            <Icon color={isSync ? "green" : "black"} w="5" h="5" as={isSync ? IoSyncCircleSharp : IoSync} cursor='pointer' onClick={onSyncFinger} />
        </Flex>
    )
}