import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { DaftarSiswa } from './components/siswa';
import { DetailSiswa } from './components/siswa/detail';
import { FormSiswa } from './components/siswa/form';
import { HomePage } from './pages/home';
import { LoginForm } from './pages/login';
import RequireAuth from './pages/require';
import { login } from './redux/userSlice';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import AfterAuth from './pages/after';
import { Dashboard } from './components/dashboard';
import { FormAdmin } from './components/admin/form';
import { Verification } from './pages/verification';
import { FormMutasiSiswa } from './components/siswa/mutasi-keluar';
import axios from './helper/axios';
import { DaftarKelas } from './components/kelas';
import { DetailKelas } from './components/kelas/detail';
import { FormSiswaManual } from './components/siswa/form/manualPage';
import { DaftarSiswaBaru } from './components/siswa-baru';
import { DaftarKamar } from './components/kamar';
import { DetailKamar } from './components/kamar/detail';
import { DaftarProgram } from './components/program';
import { DetailProgram } from './components/program/detail';
import { DaftarKandidatKui } from './components/kandidat-kui';
import { DaftarGuru } from './components/guru';
import { FormGuru } from './components/guru/form';
import { DetailGuru } from './components/guru/detail';
import { Statistik } from './components/report/statistik';
import { DaftarFingerSiswa } from './components/finger/siswa';

function App() {
  const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    
    const keepLogin = async () => {
      try {
        const res = await axios.get(`/auth`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        dispatch(
          login({
            username: res.data.result.username,
            email: res.data.result.email,
            tahun_pelajaran: res.data.tapel.tahun_pelajaran,
            semester: res.data.tapel.semester,
          })
        );
      } catch (err) {
        localStorage.removeItem('token')
        console.log(err);
      }
    };

  useEffect(() => {
   token
      ? keepLogin()
      : console.log("login first");
  });
  const router = createBrowserRouter([
    { path: '/', element: <HomePage />, children: [
      { element: <RequireAuth />, children: [
        { path: '/', element: <Dashboard /> },
        { path: '/daftar-siswa', element: <DaftarSiswa /> },
        { path: '/daftar-siswa-baru', element: <DaftarSiswaBaru /> },
        { path: '/daftar-kandidat-kui', element: <DaftarKandidatKui /> },
        { path: '/finger-siswa', element: <DaftarFingerSiswa /> },
        { path: '/tambah-siswa', element: <FormSiswa /> },
        { path: '/tambah-guru', element: <FormGuru /> },
        { path: '/tambah-siswa-manual', element: <FormSiswaManual /> },
        { path: '/tambah-admin', element: <FormAdmin /> },
        { path: '/report', element: <Statistik /> },
        { path: '/daftar-guru', element: <DaftarGuru /> },
        { path: '/daftar-kelas', element: <DaftarKelas /> },
        { path: '/daftar-kelas/:kelas', element: <DetailKelas /> },
        { path: '/daftar-program', element: <DaftarProgram /> },
        { path: '/daftar-program/:program', element: <DetailProgram /> },
        { path: '/daftar-kamar', element: <DaftarKamar /> },
        { path: '/daftar-kamar/:kamar', element: <DetailKamar /> },
        { path: '/:npy/detail-guru', element: <DetailGuru /> },
        { path: '/:nps/detail-siswa', element: <DetailSiswa /> },
        { path: '/:nps/mutasi-keluar', element: <FormMutasiSiswa /> },
      ]},
    ]},
    {
      element: <AfterAuth />, children: [
        { path: "/login", element: <LoginForm />},
        { path: "/verification/:token", element: <Verification />}
      ]
    }
  ])
  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
}

export default App;
